export default [
  {
    component: () => import('@/modules/courses/views/CourseDetailPage.vue'),
    name: 'contents',
    path: '/courses/new/:courseId/:contentId?',
    meta: {
      requiresModule: 'courses',
      layout: 'Blank',
      module: 'courses',
      requiresAuth: false,
    },
    children: [
      {
        component: () => import('@/modules/viewers/components/WbtViewer.vue'),
        name: 'wbt-viewer',
        path: 'learningmaterial/:learningMaterialId/wbt-viewer',
        meta: {
          requiresModule: 'courses',
          module: 'courses',
          layout: 'Blank',
          requiresAuth: false,
        },
      },
      {
        component: () => import('@/modules/courses/views/contents-page/course-contents/questions/QuestionsRouterView.vue'),
        name: 'questions',
        path: 'questions',
        meta: {
          requiresModule: 'courses',
          layout: 'Blank',
          requiresAuth: false,
        },
        children: [
          {
            component: () => import('@/modules/courses/views/contents-page/course-contents/questions/QuestionsIntro.vue'),
            name: 'intro',
            path: 'intro',
            meta: {
              requiresModule: 'courses',
              layout: 'Blank',
              requiresAuth: false,
            },
          },
          {
            component: () => import('@/modules/courses/views/contents-page/course-contents/questions/QuestionsDisplay.vue'),
            name: 'display',
            path: 'display/:attachmentId',
            meta: {
              requiresModule: 'courses',
              layout: 'Blank',
              requiresAuth: false,
              module: 'courses',
            },
          },
          {
            component: () => import('@/modules/courses/views/contents-page/course-contents/questions/QuestionsResult.vue'),
            name: 'result',
            path: 'result',
            meta: {
              requiresModule: 'courses',
              layout: 'Blank',
              requiresAuth: false,
            },
          },
        ],
      },
    ],
  },
  {
    component: () => import('@/modules/courses/views/CourseDetailPage.vue'),
    name: 'course-preview', // This name is used in code
    path: '/courses/preview/:courseId',
    meta: {
      layout: 'Blank',
      requiresAuth: false,
    },
  },
  {
    component: () => import('@/modules/courses/views/CourseDashboard.vue'),
    name: 'dashboard',
    path: '/courses',
    meta: {
      requiresModule: 'courses',
    },
  },
  {
    component: () => import('@/modules/courses/views/CourseDisplay.vue'),
    name: 'details',
    path: '/courses/:courseId',
    meta: {
      requiresModule: 'courses',
      noSidebar: true,
    },
  },
  {
    component: () => import('@/modules/courses/views/CourseParticipation.vue'),
    name: 'participation',
    path: '/courses/:courseId/participation/:participationId',
    meta: {
      requiresModule: 'courses',
    },
    children: [
      {
        component: () => import('@/modules/courses/views/ParticipationContent.vue'),
        name: 'content',
        path: 'content/:contentId',
        meta: {
          noMobileFooter: true,
        },
      },
      {
        component: () => import('@/modules/courses/views/QuestionsOverview.vue'),
        name: 'questions-overview',
        path: 'content/:contentId/questions-overview',
        meta: {
          noMobileFooter: true,
        },
      },
      {
        component: () => import('@/modules/courses/views/QuestionsList.vue'),
        name: 'questions-list',
        path: 'content/:contentId/questions',
        meta: {
          noMobileFooter: true,
        },
      },
      {
        component: () => import('@/modules/courses/views/QuestionsResult.vue'),
        name: 'questions-result',
        path: 'content/:contentId/questions-result',
        meta: {
          noMobileFooter: true,
        },
      },
      {
        component: () => import('@/modules/courses/views/ChapterDone.vue'),
        name: 'chapter-done',
        path: 'chapter-done/:chapterId',
        meta: {
          noMobileFooter: true,
        },
      },
    ],
  },
]
