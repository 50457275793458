import api from '@/logic/api'

export default {
  keywordsPromise: null,

  getKeywords() {
    if (this.keywordsPromise) {
      return this.keywordsPromise
    }

    this.keywordsPromise = new Promise((resolve, reject) => {
      api.get('/keywords')
        .then((response) => {
          resolve(response)
          this.keywordsPromise = null
        })
        .catch((error) => {
          this.keywordsPromise = null
          reject(error)
        })
    })

    return this.keywordsPromise
  },
  getDescriptions(keywords) {
    return api.get('/keywords/descriptions', {
      keywords,
    })
  },
}
