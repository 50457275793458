export const setupAccessibilityWidget = (store) => {
  if (store.getters['auth/config'].settings.accessibility_widget_enabled) {
    if (!document.querySelector('script[src="https://www.accessgo.de/widget/accessGoWidget.js"]')) {
      const accessGoWidgetScript = document.createElement('script')
      accessGoWidgetScript.src = 'https://www.accessgo.de/widget/accessGoWidget.js'
      accessGoWidgetScript.type = 'module'
      document.head.appendChild(accessGoWidgetScript)
    }
  }
}
