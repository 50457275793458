module.exports = {
  wbt_is_processing: 'Diese Datei wird gerade verarbeitet, bitte versuche es später erneut.',
  search_learningmaterials: 'Dateien suchen',
  wbt: {
    no_results: 'Keine Einträge vorhanden',
    status: {
      passed: 'Bestanden',
      failed: 'Nicht Bestanden',
      completed: 'Abgeschlossen',
    },
    table: {
      date: 'Datum',
      status: 'Status',
      result: 'Ergebnis',
    },
  },
  types: {
    wbt: 'WBT',
    video: 'Video',
    audio: 'Audio',
    document: 'Dokument',
    document_word: 'Word Dokument',
    document_image: 'Bild',
    document_pdf: 'PDF',
    document_powerpoint: 'Powerpoint',
    document_sheet: 'Excel',
    link: 'Link',
    no_attachment: 'Textinhalt',
  },
  startButtonLabel: {
    wbt: 'WBT starten',
    video: 'Video starten',
    audio: 'Audio starten',
    document: 'Dokument herunterladen',
    document_word: 'Dokument herunterladen',
    document_image: 'Bild öffnen',
    document_pdf: 'PDF öffnen',
    document_powerpoint: 'Powerpoint herunterladen',
    link: 'Link öffnen',
    open_office: 'Dokument öffnen',
  },
  filterLabel: {
    wbt: 'Web Based Training',
    video: 'Video',
    audio: 'Audio',
    document: 'Dokumente',
    document_word: '.docx (Word)',
    document_image: '.jpeg .png .gif (Bild)',
    document_pdf: '.pdf',
    document_powerpoint: '.pptx (Powerpoint)',
    document_sheet: '.xls (Excel)',
    no_attachment: 'Textinhalte',
  },
  sortLabels: {
    name_asc: 'Alphabetisch (A-Z)',
    name_desc: 'Alphabetisch (Z-A)',
    published_at_desc: 'Datum (neuste zuerst)',
    published_at_asc: 'Datum (älteste zuerst)',
  },
  sortShortLabels: {
    name_asc: 'A-Z',
    name_desc: 'Z-A',
    published_at_desc: 'Neuste',
    published_at_asc: 'Älteste',
  },
  go_to_learningmaterials: 'Datei öffnen',
  latest_learningmaterials: 'Neu in der Mediathek',
  materialcount: '1 Datei | %{n} Dateien',
  latest: 'Neuste',
  last_viewed: 'Zuletzt aufgerufen',
  all_categories: 'Alle Ordner A-Z',
  passed: 'Bestanden',
  with_percentage: 'mit %{score}%',
  no_wbt_on_ios: 'Leider ist derzeit die Wiedergabe von WBTs in der Store-App unter iOS nicht möglich. Bitte siehe dir den Inhalt in deinem Browser an.',
  open_wbt_in_browser: 'WBT im Browser öffnen',
  new: 'Neu',
  subfolder: 'Unterordner',
  files: 'Dateien',
}
