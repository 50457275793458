import constants from '@/constants'

export default {
  getType(learningmaterial) {
    if (learningmaterial.file_type === 'wbt') {
      return constants.LEARNINGMATERIALS.TYPE_WBT
    }
    if (learningmaterial.file_type === 'application/pdf') {
      return constants.LEARNINGMATERIALS.TYPE_DOCUMENT_PDF
    }

    const audioMimetypes = [
      'audio/mpeg',
      'audio/mp3',
      'audio/wav',
    ]
    if (audioMimetypes.includes(learningmaterial.file_type)) {
      return constants.LEARNINGMATERIALS.TYPE_AUDIO
    }

    const imageMimetypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/svg+xml',
    ]
    if (imageMimetypes.includes(learningmaterial.file_type)) {
      return constants.LEARNINGMATERIALS.TYPE_DOCUMENT_IMAGE
    }

    const wordMimetypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
    ]
    if (wordMimetypes.includes(learningmaterial.file_type)) {
      return constants.LEARNINGMATERIALS.TYPE_DOCUMENT_WORD
    }

    const powerpointMimetypes = [
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/mspowerpoint',
      'application/vnd.ms-powerpoint',
    ]
    if (powerpointMimetypes.includes(learningmaterial.file_type)) {
      return constants.LEARNINGMATERIALS.TYPE_DOCUMENT_POWERPOINT
    }

    const zipMimetypes = [
      'application/zip',
      'application/x-zip-compressed',
    ]
    if (zipMimetypes.includes(learningmaterial.file_type)) {
      return constants.LEARNINGMATERIALS.TYPE_ZIP
    }

    const sheetMimetypes = [
      'application/msexcel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ]
    if (sheetMimetypes.includes(learningmaterial.file_type)) {
      return constants.LEARNINGMATERIALS.TYPE_DOCUMENT_SHEET
    }

    if (learningmaterial.file_type === 'azure_video') {
      return constants.LEARNINGMATERIALS.TYPE_VIDEO
    }

    if (learningmaterial.link) {
      try {
        const linkURL = new URL(learningmaterial.link)
        const youtubeHostnames = [
          'www.youtube.com',
          'm.youtube.com',
          'youtube.com',
          'youtu.be',
        ]
        if (youtubeHostnames.includes(linkURL.hostname)) {
          return constants.LEARNINGMATERIALS.TYPE_VIDEO
        }
        return constants.LEARNINGMATERIALS.TYPE_LINK
        // eslint-disable-next-line no-empty
      } catch {}
    }

    if (learningmaterial.file_type === '') {
      return constants.LEARNINGMATERIALS.TYPE_NO_ATTACHMENT
    }

    return constants.LEARNINGMATERIALS.TYPE_DOCUMENT
  },
  getTypeLabel(type) {
    const labels = {
      [constants.LEARNINGMATERIALS.TYPE_WBT]: 'wbt',
      [constants.LEARNINGMATERIALS.TYPE_VIDEO]: 'video',
      [constants.LEARNINGMATERIALS.TYPE_AUDIO]: 'audio',
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT]: 'document',
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_WORD]: 'document_word',
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_IMAGE]: 'document_image',
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_PDF]: 'document_pdf',
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_POWERPOINT]: 'document_powerpoint',
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_SHEET]: 'document_sheet',
      [constants.LEARNINGMATERIALS.TYPE_LINK]: 'link',
      [constants.LEARNINGMATERIALS.TYPE_NO_ATTACHMENT]: 'no_attachment',
    }
    return labels[type]
  },
  getTypeIcon(type) {
    const icons = {
      [constants.LEARNINGMATERIALS.TYPE_WBT]: 'wbt-web-based-training',
      [constants.LEARNINGMATERIALS.TYPE_VIDEO]: 'video',
      [constants.LEARNINGMATERIALS.TYPE_AUDIO]: 'audio',
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT]: 'unknown',
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_WORD]: 'text-document',
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_IMAGE]: 'png',
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_PDF]: 'pdf',
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_POWERPOINT]: 'presentation',
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_SHEET]: 'sheet',
      [constants.LEARNINGMATERIALS.TYPE_ZIP]: 'zip',
      [constants.LEARNINGMATERIALS.TYPE_LINK]: 'link',
      [constants.LEARNINGMATERIALS.TYPE_NO_ATTACHMENT]: 'text',
    }
    return icons[type]
  },
  getBroadType(type) {
    const mappings = {
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_WORD]: constants.LEARNINGMATERIALS.TYPE_DOCUMENT,
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_IMAGE]: constants.LEARNINGMATERIALS.TYPE_DOCUMENT,
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_PDF]: constants.LEARNINGMATERIALS.TYPE_DOCUMENT,
      [constants.LEARNINGMATERIALS.TYPE_DOCUMENT_POWERPOINT]: constants.LEARNINGMATERIALS.TYPE_DOCUMENT,
    }
    return mappings[type] ?? type
  },
  mapMaterial(material) {
    material.type = this.getType(material)
    material.typeIcon = this.getTypeIcon(material.type)
    material.typeLabel = this.getTypeLabel(material.type)
    return material
  },
  isOfficeFile(fileUrl) {
    if (!fileUrl) {
      return false
    }
    const parts = fileUrl.split('.')
    const extension = parts[parts.length - 1].toLowerCase()
    const officeExtensions = [
      'doc',
      'dot',
      'wbk',
      'docx',
      'docm',
      'dotx',
      'dotm',
      'docb',
      'xls',
      'xlt',
      'xlm',
      'xlsx',
      'xlsm',
      'xltx',
      'xltm',
      'xlsb',
      'xla',
      'xlam',
      'xll',
      'xlw',
      'ppt',
      'pptx',
      'pot',
      'pps',
      'pptm',
      'potx',
      'potm',
      'ppam',
      'ppsx',
      'ppsm',
      'sldx',
      'sldm',
    ]
    return officeExtensions.includes(extension)
  },
}
